* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .heading {
    margin-left: 33px;
  }

  .footer {
    background: #111e6c;
    margin-bottom: 0px;
  }
  
  .footer .footer-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 3.5rem;
    padding-left: 40px;
    padding-right: 40px;
  }
  
  .footer-row .footer-col h4 {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  .footer-col .links {
    margin-top: 10px;
    font-weight: 400;
  }
  
  .footer-col .links li {
    list-style: none;
    margin-bottom: 10px;
  }
  
  .footer-col .links li a {
    text-decoration: none;
    color: #bfbfbf;
  }
  
  .footer-col .links li a:hover {
    color: #fff;
  }
  
  .footer-col p {
    margin: 20px 0;
    color: #bfbfbf;
    max-width: 300px;
  }
  
  .footer-col form {
    display: flex;
    gap: 5px;
  }
  
  .footer-col input {
    height: 40px;
    border-radius: 6px;
    background: none;
    width: 100%;
    outline: none;
    border: 1px solid #7489c6;
    caret-color: #fff;
    color: #fff;
    padding-left: 10px;
  }
  
  .footer-col input::placeholder {
    color: #ccc;
  }
  
  .footer-col form button {
    background: #fff;
    outline: none;
    border: none;
    padding: 10px 15px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: 0.2s ease;
  }
  .copyright-logo{
    color: #111e6c;
    font-weight: bolder;
  }
  .copyright p {
    font-size: 14px;
  }
  
  .footer-col form button:hover {
    background: #cecccc;
  }
  
  .footer-col .icons {
    display: flex;
    margin-top: 30px;
    gap: 30px;
    cursor: pointer;
  }
  
  .footer-col .icons i {
    color: #afb6c7;
  }
  
  .footer-col .icons i:hover {
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .footer {
      position: relative;
      bottom: 0;
      left: 0;
      transform: none;
      width: 100%;
      border-radius: 0;
    }
  
    .footer .footer-row {
      padding: 20px;
      gap: 1rem;
    }
  
    .footer-col form {
      display: block;
    }
  
    .footer-col form :where(input, button) {
      width: 100%;
    }
  
    .footer-col form button {
      margin: 10px 0 0 0;
  }
  .copyright p {
    font-size: 10px;
  }
  }