.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Adjust this based on your layout needs */
  }
  
  .loader {
    width: 48px;
    height: 48px;
    border: 3px solid #aeaeae;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 3px solid;
    border-color: #111e6c transparent;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  