* {
  padding: 0;
  margin: 0;
}
.mv-content {
  /* border: 2px solid black; */
  margin: 0 auto;
  width: 85%;
}
.mv-col {
  /* border: 2px solid rgb(250, 5, 5); */
  background-color: white;
  box-shadow: 2px 2px 10px #ccc;
  padding: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 20px;
}
.mission-img {
  width: 50%;
}
.mv-col h4 {
  color: #111e6c;
  font-weight: 700;
  margin-top: 10px;
}

.mv-col p {
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
}
@media (max-width: 780px){
  .mv-col{
    margin-left: 0;
  }
}
