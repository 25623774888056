#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

/* body {
  background: #ffffff;
  color: #000;
  margin: 0;
  padding: 0;
} */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.swiper-slide img {
  display: block;
  width: 68%;
  height: 68%;
  object-fit: cover;

  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 500ms;
}
.swiper-slide img:hover{
  transform: scale(1.07);
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2)


}

.swiper {
  margin-left: auto;
  margin-right: auto;
}


/* Slide.css or your custom CSS file */
.mySwiper {
  position: relative;
}

.custom-pagination-container {
  position: absolute;
  bottom: 10px; /* Adjust this value according to your needs */
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.swiper-pagination {
  position: static;

}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin: 0 5px;

  background-color: #111e67;
}
@media screen and (max-width: 700px){
  .swiper-pagination-bullet {
      width: 7px;
      height: 7px;
      margin: 0 5px;
   
      background-color: #111e67;
    }
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size:12px;
  color: #111e67;
}
.swiper-slide img {
  width: 68%;
  height: 68%;
}
}


.swiper-button-next,
.swiper-button-prev {
width: 30px; 
height: 30px; 
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size:25px;
  color: #111e67;
}